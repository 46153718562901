@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

*, *:before, *:after {
  box-sizing: border-box; }

img {
  max-width: 100%;
  height: auto; }

ul {
  padding-left: 0;
  margin: 0;
  list-style: none; }

li {
  list-style: none; }

.container {
  width: 1400px;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 1rem;
  position: relative;
  display: flex; }

.loading-main {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff; }
  .loading-main .container {
    height: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    align-content: center; }

.loading-container {
  width: 340px;
  height: 50vh;
  max-width: 100%;
  position: relative; }

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg); } }

@keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg); } }

.three-quarters-circle {
  width: 340px;
  height: 340px;
  position: absolute;
  left: 67px;
  top: 57px;
  box-sizing: border-box;
  border: 2px solid #0D2419;
  border-radius: 100%;
  border-top-color: transparent;
  background-color: transparent;
  -webkit-animation: rotation 0.8s linear infinite;
          animation: rotation 0.8s linear infinite; }

.full-circle {
  width: 0;
  height: 0;
  position: absolute;
  left: 100px;
  top: 100px;
  border-radius: 100%;
  border: 120px solid #0D2419;
  border-right-color: #394B42;
  border-top-color: #394B42;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg); }

@-webkit-keyframes showLeft {
  from {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  25% {
    -webkit-transform: rotate(-135deg);
            transform: rotate(-135deg); }
  50% {
    -webkit-transform: rotate(-135deg);
            transform: rotate(-135deg); }
  75% {
    -webkit-transform: rotate(-315deg);
            transform: rotate(-315deg); }
  to {
    -webkit-transform: rotate(-315deg);
            transform: rotate(-315deg); } }

@keyframes showLeft {
  from {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  25% {
    -webkit-transform: rotate(-135deg);
            transform: rotate(-135deg); }
  50% {
    -webkit-transform: rotate(-135deg);
            transform: rotate(-135deg); }
  75% {
    -webkit-transform: rotate(-315deg);
            transform: rotate(-315deg); }
  to {
    -webkit-transform: rotate(-315deg);
            transform: rotate(-315deg); } }

@-webkit-keyframes showRight {
  from {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    z-index: 6; }
  25% {
    -webkit-transform: rotate(-135deg);
            transform: rotate(-135deg); }
  50% {
    -webkit-transform: rotate(-135deg);
            transform: rotate(-135deg);
    z-index: 6; }
  50.001% {
    -webkit-transform: rotate(-135deg);
            transform: rotate(-135deg);
    z-index: 5; }
  75% {
    -webkit-transform: rotate(-315deg);
            transform: rotate(-315deg);
    z-index: 5; }
  99.999% {
    -webkit-transform: rotate(-315deg);
            transform: rotate(-315deg);
    z-index: 5; }
  to {
    -webkit-transform: rotate(-315deg);
            transform: rotate(-315deg);
    z-index: 6; } }

@keyframes showRight {
  from {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    z-index: 6; }
  25% {
    -webkit-transform: rotate(-135deg);
            transform: rotate(-135deg); }
  50% {
    -webkit-transform: rotate(-135deg);
            transform: rotate(-135deg);
    z-index: 6; }
  50.001% {
    -webkit-transform: rotate(-135deg);
            transform: rotate(-135deg);
    z-index: 5; }
  75% {
    -webkit-transform: rotate(-315deg);
            transform: rotate(-315deg);
    z-index: 5; }
  99.999% {
    -webkit-transform: rotate(-315deg);
            transform: rotate(-315deg);
    z-index: 5; }
  to {
    -webkit-transform: rotate(-315deg);
            transform: rotate(-315deg);
    z-index: 6; } }

.two-quarters-circle {
  width: 0;
  height: 0;
  position: absolute;
  left: 100px;
  top: 100px;
  border: 120px solid transparent;
  border-radius: 100%;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg); }

#half-left {
  z-index: 6;
  border-left-color: #394B42;
  border-bottom-color: #394B42;
  -webkit-animation: showLeft 2s linear infinite;
          animation: showLeft 2s linear infinite; }

#half-right {
  z-index: 5;
  border-bottom-color: #0D2419;
  border-left-color: #0D2419;
  -webkit-animation: showRight 2s linear infinite;
          animation: showRight 2s linear infinite;
  -webkit-animation-delay: 0.50s;
          animation-delay: 0.50s; }

